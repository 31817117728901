import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/project/list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/project/get?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/project/save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/project/save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/project/delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        imageItems(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/project/image-list?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addImages(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/project/image-save', dataInfo, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeImage(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/project/image-delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        imageSorting(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/project/image-sorting', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        spaceItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/definition/space-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        floorMaterialItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/definition/floor-material-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        projectStyleItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/definition/project-style-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        statesItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/address/states-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        featureItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/definition/feature-list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}


